<template>
  <vsvg-icon
    :data="svg"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'DynamicIconLoader',

  props: {
    path: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    svg: {}
  }),

  async created() {
    this.svg = (await this.getIcon());
  },

  methods: {
    getIcon() {
      return import(`../assets/svg/${this.path}`);
    }
  }
}
</script>

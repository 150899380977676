<template>
  <div class="white-card little-card">
    <div class="red-icon">
      <dynamic-icon-loader
        :path="`activity-type/${statKeyIcon}-icon.svg`"
        scale="1"
        :color="brand.styles.primary"
      />

      <p class="title">
        {{ statKeyLabel }}
      </p>
    </div>
    <div class="count">
      <p v-if="!isLoading">
        {{ statValue }}
      </p>
      <transition name="fadeLoading">
        <div
          v-if="isLoading"
          v-loading="isLoading"
          class="container"
          style="min-height: 49px"
        ></div>
      </transition>
      <p class="cat">
        {{ statKeyMetric }}
      </p>
    </div>
  </div>
</template>

<script>
import DynamicIconLoader from '@/services/dynamic-icon-loader'

export default {
  components: {
    DynamicIconLoader,
  },

  props: {
    statKey: {
      type: String,
      default: ''
    },
    statValue: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    statKeyIcon() {
      return this.resolveStatKey()
    },
    statKeyLabel() {
      const label = this.resolveStatKey()
        
      if (label == 'moment') {
        return 'Photo'
      }

      if (label == 'event') {
        return 'Sport'
      }

      return label
    },
    statKeyMetric() {
      return this.statKey.includes('total_distance') ? 'miles' : 'uploads'
    },
  },

  methods: {
    resolveStatKey() {
      if (this.statKeyMetric == 'miles') {
        return this.statKey.replace('_total_distance', '')
      }

      return this.statKey.replace('_logged', '')
    },
  }
}
</script>
